import * as React from "react";
import * as ReactDOM from "react-dom";
import moment from "moment";
import { Provider } from "react-redux";
import { getConfig } from "@momenta/common/config/getConfig";
import { AppInsights } from "applicationinsights-js";
import AppInsightsLogging from "appinsights-usage";
import { enrichLogsWithUser } from "@momenta/common/logging/appInsightsUserLogEnricher";

import App from "./app";
import { initialiseLocation, store, initialiseRegion } from "./startup";

import "../../../../node_modules/semantic-ui-less/semantic.less";
import "react-toastify/dist/ReactToastify.css";
import "./style/index.scss";

const instrumentationKey = getConfig().ApplicationInsightsInstrumentationKey;
const options = { instrumentationKey };
AppInsights.downloadAndSetup(options);
AppInsightsLogging.init(options);

const locale = (window.navigator as any).userLanguage || window.navigator.language;
moment.locale(locale);

store.dispatch(initialiseLocation());
store.dispatch(initialiseRegion());
enrichLogsWithUser(store);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("approot")
);
