import * as React from "react";
import { connect } from "react-redux";
import { List } from "semantic-ui-react";
import { CandidateState } from "@momenta/common";

import { AppState, Candidate } from "./model";

interface CandidatesStateProps {
    candidates: Candidate[];
}

const Candidates: React.StatelessComponent<CandidatesStateProps> = (props) => (
    <div>
        <h1>Candidates for Review</h1>

        <List>
            {props.candidates.map(c => (
                <List.Item key={c.id}>
                    <List.Header>{c.associate.fullName}</List.Header>
                    {CandidateState[c.state]}
                </List.Item>
            ))}
        </List>
    </div>
);

const mapStateToProps = (state: AppState): CandidatesStateProps => {
    return {
        candidates: state.candidates
    };
};

export default connect(mapStateToProps)(Candidates);
