import * as React from "react";
import { Container, Menu, Grid } from "semantic-ui-react";
import { Fragment, Link } from "redux-little-router";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { CdnImg, EnvironmentLabel } from "@momenta/common";
import { Forbidden } from "@momenta/common/auth";
import { Authorize } from "reauthorize";
import { ErrorBoundaryRouted } from "@momenta/common/ErrorBoundaryRouted";
import { LocalText } from "@momenta/common/internationalisation/LocalText";

import Timesheets from "./timesheets/Timesheets";
import { TimesheetDetail } from "./timesheets/TimesheetDetail";
import Candidates from "./candidates/Candidates";
import { AppState } from "./model";
import { CurrentUser, UserRoles } from "./auth";
import { Landing } from "./Landing";
import { Preferences } from "./preferences/Preferences";

interface AppStateProps {
    currentUser: CurrentUser;
}

const App: React.SFC<AppStateProps> = ({ currentUser }) => {

    const authMenu = currentUser.authenticated
        ? <Menu.Item as="a" href="/account/signout">Sign-Out</Menu.Item>
        : <Menu.Item as="a" href="/account/signin">Sign-In</Menu.Item>;

    const unAuthenticated = () => { return currentUser.authenticated === false; };
    const authenticated = () => { return currentUser.authenticated; };

    return (
        <>
            <div className={unAuthenticated() ? "unauthenticated" : undefined}>
                <Menu as="header" className="top-menu" borderless>
                    <Container>
                        <Menu.Item as={Link} href="/" className="client logo"><CdnImg src="/momenta-logo-white-text.svg" /></Menu.Item>

                        {authenticated() && <Menu.Item as={Link} href="/preferences">Preferences</Menu.Item>}

                        <Authorize authorize={UserRoles.timesheetRead}>
                            <Menu.Item as={Link} href="/timesheets">Timesheet Approval</Menu.Item>
                        </Authorize>
                        <Menu.Item><EnvironmentLabel /></Menu.Item>

                        <Menu.Menu position="right"  className="account">
                            {authMenu}
                        </Menu.Menu>
                    </Container>
                </Menu>

                <Fragment forRoute="/" withConditions={unAuthenticated}>
                    <Landing />
                </Fragment>

                <Container>
                    <ToastContainer hideProgressBar />
                    <ErrorBoundaryRouted>
                        <Fragment forRoute="/" withConditions={authenticated}>
                            <>
                                <Fragment forRoute="/forbidden">
                                    <Forbidden />
                                </Fragment>
                                <Fragment forRoute="/candidates">
                                    <Candidates />
                                </Fragment>
                                <Fragment forRoute="/timesheets">
                                    <>
                                        <Fragment forRoute="/:timesheetId">
                                            <TimesheetDetail />
                                        </Fragment>

                                        <Fragment forRoute="/">
                                            <Timesheets />
                                        </Fragment>
                                    </>
                                </Fragment>
                                <Fragment forRoute="/preferences">
                                    <Preferences />
                                </Fragment>
                            </>
                        </Fragment>
                    </ErrorBoundaryRouted>
                </Container>

                {unAuthenticated() && <footer className="landing-footer">
                    <Container>
                        <Grid>
                            <Grid.Column computer={16} mobile={16} floated="right" textAlign="right">
                                <strong><LocalText keyName="clientLandingPhoneNumber" /></strong>
                            </Grid.Column>
                        </Grid>
                    </Container>
                </footer>}
            </div>
        </>
    );
};

const mapStateToProps = (state: AppState): AppStateProps => {
    return {
        currentUser: state.currentUser,
    };
};

export default connect(mapStateToProps)(App);
