import * as React from "react";
import { TimezonePreference } from "@momenta/common/currentUser/TimezonePreference";
import { connect } from "react-redux";

import { ClientUser } from "../clientUser/model";
import { loadClientUser, saveClientUser } from "../clientUser/actions";
import { clientUserSelector } from "../clientUser/selectors";

interface PreferencesStateProps {
    clientUser: ClientUser;
}

interface PreferencesDispatchProps {
    saveClientUser: (clientUser: ClientUser) => Promise<void>;
}

const PreferencesUnconnected: React.FunctionComponent<PreferencesStateProps & PreferencesDispatchProps> =
    ({ clientUser, saveClientUser: save }) => {
        const timezone = clientUser && clientUser.timezone;

        const saveTimezone = async (value: string) => {
            const user = { ...clientUser, timezone: value };
            await save(user);
        };

        return (
            <>
                <h2>Preferences</h2>
                <TimezonePreference timezone={timezone} save={saveTimezone} />
            </>
        );
    };

const mapStateToProps = (state: any): PreferencesStateProps => ({
    clientUser: clientUserSelector(state)
});

const mapDispatchToProps = (dispatch: any): PreferencesDispatchProps => ({
    saveClientUser: async (clientUser: ClientUser) => {
        await dispatch(saveClientUser(clientUser));
        await dispatch(loadClientUser());
    }
});

export const Preferences = connect(mapStateToProps, mapDispatchToProps)(PreferencesUnconnected);
