import * as React from "react";
import { Container, Grid } from "semantic-ui-react";
import { LocalText } from "@momenta/common/internationalisation";

export const Landing: React.FC<{}> = () => (
    <>
        <div className="landing">
            <Container>
                <Grid>
                    <Grid.Column computer={10} mobile={16} >
                        <h1>Momenta people client portal</h1>

                        <p>
                            Welcome to <span className="secondary-colour">Momenta People</span> client portal.
                            Here you will review your teams’ timesheets for approval.
                            Kindly ensure to thoroughly review the timesheet information and approve the timesheets within the required timescale.
                        </p>

                        <strong> For more information or assistance contact: <LocalText keyName="clientLandingEmailAddress">
                            {value => (
                                <a href={`mailto:${value}`}>{value}</a>
                            )}
                        </LocalText>
                        </strong>
                    </Grid.Column>
                </Grid>
            </Container>
        </div>
        <div className="landing-image">
        </div>
    </>
);
