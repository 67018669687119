import { UserRoles } from "../auth";

import { loadCandidates } from "./actions";

export default {
    "/candidates": {
        title: "Candidates",
        resolve: loadCandidates,
        authorize: [UserRoles.candidateRead]
    }
};
