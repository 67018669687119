import axios, { AxiosStatic } from "axios";

import { ClientUser } from "./model";

export class ClientUserApi {
    private readonly apiUrl = "api/clientuser";
    private axios: AxiosStatic;

    constructor(ax: AxiosStatic = axios) {
        this.axios = ax;
    }

    public async get(): Promise<ClientUser> {
        const response = await this.axios.get(`${this.apiUrl}`);
        return response.data as ClientUser;
    }

    public async save(model: ClientUser): Promise<ClientUser> {
        const response = await this.axios.put(`${this.apiUrl}/${model.id}`, model);
        return response.data as ClientUser;
    }
}
