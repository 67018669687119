import { getReducer } from "@momenta/common/auth";
import { reducer as globalConfigs } from "@momenta/common/globalConfig";
import { region } from "@momenta/common/internationalisation/reducer";

import { candidates } from "../candidates/reducer";
import { timesheets } from "../timesheets/reducer";
import { createReducer } from "../hierarchicalConfiguration";
import { CurrentUser } from "../auth";
import { clientUsers } from "../clientUser/reducer";

const currentUser = getReducer<CurrentUser>({ authenticated: false, roles: [] });
const timeTypeConfiguration = createReducer("timeTypeConfiguration");
const expenseTypeConfiguration = createReducer("expenseTypeConfiguration");
const bonusTypeConfiguration = createReducer("bonusTypeConfiguration");

export const reducers = {
    candidates,
    timesheets,
    timeTypeConfiguration,
    expenseTypeConfiguration,
    bonusTypeConfiguration,
    currentUser,
    globalConfigs,
    region,
    clientUsers
};
