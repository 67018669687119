import { ClientUser } from "./model";
import { ClientUserAction } from "./actions";
import * as actions from "./actionTypes";

export function clientUsers(state: ClientUser[] = [], action: ClientUserAction): ClientUser[] {
    switch (action.type) {
        case actions.LOAD_CLIENT_USER_SUCCESS:
            return [action.payload];
        case actions.SAVE_CLIENT_USER_SUCCESS:
            return state.map(c => clientUser(c, action));
        default:
            return state;
    }
}

function clientUser(state: ClientUser, action: ClientUserAction) {
    switch (action.type) {
        case actions.SAVE_CLIENT_USER_SUCCESS:
            if (action.payload.id === state.id) {
                return action.payload;
            }
            return state;
        default:
            return state;
    }
}
