import axios, { AxiosStatic } from "axios";

import { Candidate } from "./model";

export class CandidateApi {

    private readonly apiUrl: string = "/api/candidate";

    private axios: AxiosStatic;

    constructor(ax: AxiosStatic) {
        this.axios = ax;
    }

    public async get(): Promise<Candidate[]> {
        const response = await this.axios.get(this.apiUrl);
        return response.data as Candidate[];
    }
}

export default new CandidateApi(axios);
