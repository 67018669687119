import { Payload } from "@neworbit/redux-helpers";
import { loadAndTrack } from "redux-request-loading";

import * as actions from "./actionTypes";
import { ClientUser } from "./model";
import { ClientUserApi } from "./clientUserApi";

const clientUserApi = new ClientUserApi();

export type ClientUserAction = (
    { type: actions.SAVE_CLIENT_USER_SUCCESS } & Payload<ClientUser>
  | { type: actions.LOAD_CLIENT_USER_SUCCESS } & Payload<ClientUser>);

export function saveClientUserSuccess(payload: ClientUser): ClientUserAction {
    return {
        type: actions.SAVE_CLIENT_USER_SUCCESS,
        payload
    };
}

export function loadClientUserSuccess(payload: ClientUser): ClientUserAction {
    return {
        type: actions.LOAD_CLIENT_USER_SUCCESS,
        payload
    };
}

export function saveClientUser(clientUser: ClientUser) {
    return async (dispatch: any) => {
        await loadAndTrack(dispatch, "saveClientUser", clientUserApi.save(clientUser));
    };
}

export function loadClientUser() {
    return async (dispatch: any) => {
        const result = await loadAndTrack(dispatch, "loadClientUser", clientUserApi.get());
        dispatch(loadClientUserSuccess(result));
    };
}
