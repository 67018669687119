import * as React from "react";
import { connect } from "react-redux";
import { Grid, Header } from "semantic-ui-react";
import { push, State as RouterState } from "redux-little-router";
import { Input } from "@neworbit/simpleui-input";
import { stringSort } from "@momenta/common";
import { AppState, Timesheet, TimesheetStateEnum, TimesheetStateOption } from "@momenta/common/timesheets";

import { TimesheetList } from "./TimesheetList";

interface TimesheetsStateProps {
    timesheets: Timesheet[];
}

interface TimesheetDispatchProps {
    onFilterChange: (timesheetState: string) => void;
}

interface TimesheetState {
    timesheetState: number;
}

export class Timesheets extends React.Component<TimesheetsStateProps & TimesheetDispatchProps, TimesheetState> {

    public state: TimesheetState = {
        timesheetState: TimesheetStateEnum.Submitted
    };

    public render() {
        const options = Object.keys(TimesheetStateOption)
            .sort(stringSort(s => TimesheetStateOption[s]))
            .filter(o => o !== "0")
            .map(key => ({ text: TimesheetStateOption[key], value: +key }));

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <Header content="Timesheets" />
                    </Grid.Column>

                    <Grid.Column width={4} textAlign="right">
                        <Input.DropdownNumber
                            placeholder="Filter Timesheets"
                            options={options}
                            value={this.state.timesheetState}
                            onChange={this.onFilterChange}
                            dynamicOptions
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>

                    <Grid.Column width={16}>
                        <TimesheetList
                            timesheets={this.props.timesheets}
                            timesheetState={this.state.timesheetState}
                            basePath={"/timesheets"}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    private onFilterChange = (value: number) => {

        if (value === this.state.timesheetState) {
            return;
        }

        this.setState({
            timesheetState: value
        });

        this.props.onFilterChange(TimesheetStateOption[value]);
    }
}

const mapStateToProps = (state: AppState & RouterState): TimesheetsStateProps => ({
    timesheets: state.timesheets || [],
});

const mapDispatchToProps = (dispatch: any): TimesheetDispatchProps => ({
    onFilterChange: (state: string) => dispatch(push({ query: { state }, pathname: undefined }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Timesheets);
