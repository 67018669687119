import { User } from "reauthorize";

export interface CurrentUser extends User {
    id?: number;
    emailAddress?: string;
    groups?: string[];
    clientsManaged?: number[];
}

export interface AuthState {
    currentUser: CurrentUser;
}

export const UserRoles = {
    timesheetRead: "timesheet-read",
    timesheetWrite: "timesheet-write",
    candidateRead: "candidate-read",
    candidateWrite: "candidate-write",
};
