import { initializeCurrentLocation, replace } from "redux-little-router";
import { loadAndTrack } from "redux-request-loading";
import { getCurrentTimeZone } from "@momenta/common/utils/getCurrentTimeZone";

import { AppState } from "../model";
import { currentUserApi, getCurrentUserSuccess } from "../auth";
import { ClientUserApi } from "../clientUser/clientUserApi";
import { loadClientUserSuccess, saveClientUser } from "../clientUser/actions";

const clientUserApi = new ClientUserApi();

const initialise = async (dispatch: any, getState: () => AppState) => {

    const result = await loadAndTrack(dispatch, "getCurrentUser", currentUserApi.get());
    await dispatch(getCurrentUserSuccess(result));

    if (result.authenticated) {
        const clientUser = await loadAndTrack(dispatch, "loadClientUser", clientUserApi.get());
        await dispatch(loadClientUserSuccess(clientUser));

        if (clientUser.timezone === null) {
            let user = { ...clientUser };
            const timezone = getCurrentTimeZone();

            user = { ...user, timezone };

            await dispatch(saveClientUser(user));
        }
    }

    let initialLocation = getState().router;

    if (initialLocation.pathname === "/") {
        if (result.authenticated) {
            dispatch(replace("/timesheets"));
            return;
        }
    }

    initialLocation = getState().router;

    if (initialLocation) {
        dispatch(initializeCurrentLocation(initialLocation));
    }
};

export function initialiseLocation() {
    return (dispatch: any, getState: () => AppState) => initialise(dispatch, getState);
}
