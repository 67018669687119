import * as React from "react";
import { Grid, Header, Label, List } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { getEndDateTimesheet, Timesheet, TimesheetStateEnum } from "@momenta/common/timesheets";
import { dateSortDesc } from "@momenta/common";

export interface TimesheetListProps {
    timesheets: Timesheet[];
    timesheetState: TimesheetStateEnum;
    basePath?: string;
}

export const TimesheetList: React.FC<TimesheetListProps> = ({
    timesheets,
    timesheetState,
    basePath
}) => {

    const timesheetStateLabel = (timesheet: Timesheet) => {

        switch (timesheet.state) {
            case TimesheetStateEnum.Approved:
                return <Label content="Approved" icon="check" color="green" size="tiny" />;
            case TimesheetStateEnum.Submitted:
                return <Label content="Submitted" icon="check" color="blue" size="tiny" />;
            case TimesheetStateEnum.Rejected:
                return <Label content="Rejected" icon="edit" color="red" size="tiny" />;
            default:
                return <Label content="Incomplete" icon="warning" color="yellow" size="tiny" />;
        }
    };

    const approvedOrRejectedDate = (timesheet: Timesheet) => {
        if (timesheet.state === TimesheetStateEnum.Approved) {
            return timesheet.dateApproved && timesheet.dateApproved.format("lll");
        }
        if (timesheet.state === TimesheetStateEnum.Rejected) {
            return timesheet.dateRejected && timesheet.dateRejected.format("lll");
        }
        return "";
    };

    const isApprovedOrRejected = () => {
        return timesheetState === TimesheetStateEnum.Approved || timesheetState === TimesheetStateEnum.Rejected;
    };

    const isRejected = () => timesheetState === TimesheetStateEnum.Rejected;

    return (
        <>
            <List size="tiny" divided relaxed selection className="timesheet-list">

                {timesheets && timesheets.sort(dateSortDesc(c => c.start)).map((timesheet, index) => (
                    <List.Item as={Link} href={`${basePath}/${timesheet.id}`} key={index}>
                        <Grid columns={isRejected() ? 6 : 5} verticalAlign="middle">

                            <Grid.Column width={1}>
                                <List.Icon name="calendar outline" size="huge" verticalAlign="middle" />
                            </Grid.Column>

                            <Grid.Column>
                                <b>{timesheet.start.format("ll")} - {timesheet.end?.format("ll") ?? getEndDateTimesheet(timesheet).format("ll")}</b>
                                <br />
                                {timesheet.invoicePeriod.candidate.role.project.name}
                                <br />
                                {timesheet.invoicePeriod.candidate.role.title}
                            </Grid.Column>

                            <Grid.Column>
                                Associate
                                <br />
                                <Header>{timesheet.invoicePeriod.candidate.associate.fullName}</Header>
                            </Grid.Column>

                            {
                                isApprovedOrRejected() ?
                                    <Grid.Column>
                                        {isRejected() ? "Rejected By" : "Approved By"}
                                        <br />
                                        <b>{timesheet.approver}</b>
                                        <br />
                                        {approvedOrRejectedDate(timesheet)}
                                    </Grid.Column>
                                    :
                                    <Grid.Column />
                            }
                            {
                                isRejected() &&
                                <Grid.Column>
                                    {timesheet.rejectionReason}
                                </Grid.Column>
                            }

                            <Grid.Column floated="right">
                                {timesheet.isAdjusted &&
                                    <Label content="Adjustment" icon="newspaper outline" color="blue" size="tiny" />
                                }
                                {timesheetStateLabel(timesheet)}
                            </Grid.Column>
                        </Grid>
                    </List.Item>
                ))}
            </List>

            {(timesheets === undefined || timesheets.length === 0) &&
                <Header content="No timesheets found" textAlign="center" />
            }
        </>
    );
};

export default TimesheetList;
