import { beginAjaxCall, Payload } from "@neworbit/redux-helpers";

import * as actions from "./actionTypes";
import { Candidate } from "./model";
import CandidateApi from "./CandidateApi";

export type CandidateAction =
    ({ type: actions.LOAD_CANDIDATES_SUCCESS } & Payload<Candidate[]>);

export function loadCandidatesSuccess(payload: Candidate[]): CandidateAction {
    return {
        type: actions.LOAD_CANDIDATES_SUCCESS,
        payload
    };
}

export function loadCandidates() {
    return async (dispatch: any) => {
        dispatch(beginAjaxCall("loadCandidates"));
        const candidates = await CandidateApi.get();
        dispatch(loadCandidatesSuccess(candidates));
    };
}
