import { AppState } from "@momenta/common/timesheets";
import { loadGlobalConfig } from "@momenta/common/globalConfig/actions";

import { UserRoles } from "../auth";
import { loadConfiguration } from "../hierarchicalConfiguration";

import { loadTimesheet, loadTimesheets } from "./actions";
import { timesheetSelector } from "./selectors";

export default {
    "/timesheets": {
        "title": "Timesheets",
        "authorize": [UserRoles.timesheetRead],
        "resolve": loadTimesheets,
        "ignoreParentResolve": true,
        "/:timesheetId": {
            title: (state: AppState) => "Timesheet - " + timesheetSelector(state).start.format("L"),
            resolve: [
                loadTimesheet,
                loadConfiguration("timeTypeConfiguration"),
                loadConfiguration("expenseTypeConfiguration"),
                loadConfiguration("bonusTypeConfiguration"),
                loadGlobalConfig
            ]
        }
    },
};
