import moment from "moment";
import { ConfigTypeName, HierarchicalConfiguration } from "@momenta/common/hierarchicalConfiguration";
import axios from "axios";
import { makeRequest, PageResult } from "@momenta/common";

class HierarchicalConfigurationApi {
    public async get<T extends HierarchicalConfiguration>(type: ConfigTypeName, id: number): Promise<PageResult<T>> {

        const request = makeRequest(`/api/candidate/${id}/${type}`, {
            orderby: "Type/Name"
        });

        const response = await axios.get(request);
        const { items, count } = response.data as PageResult<T>;

        return {
            items: items.map(this.parseModel),
            count,

        };
    }

    private parseModel<T extends HierarchicalConfiguration>(model: T): T {
        const data = model as any;
        return {
            ...data,
            start: model.start && moment(model.start),
            end: model.end && moment(model.end)
        };
    }
}

const hierarchicalConfigurationApi =  new HierarchicalConfigurationApi();

export {
    hierarchicalConfigurationApi
};
