import candidateRoutes from "./candidates/routes";
import timesheetRoutes from "./timesheets/routes";
import { routes as preferenceRoutes } from "./preferences/routes";

const routes: any = {
    "/": {
        title: "Home",
        authorize: false
    },
    "/forbidden": {
        authorize: false
    },
    ...preferenceRoutes,
    ...candidateRoutes,
    ...timesheetRoutes
};

export {
    routes
};
