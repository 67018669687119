import * as actions from "./actionTypes";
import { CandidateAction } from "./actions";
import { Candidate } from "./model";

export function candidates(state: Candidate[] = [], action: CandidateAction): Candidate[] {
    switch (action.type) {
        case actions.LOAD_CANDIDATES_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
