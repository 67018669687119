import { Payload } from "@neworbit/redux-helpers";
import { AppState, Timesheet } from "@momenta/common/timesheets";
import { loadAndTrack } from "redux-request-loading";

import * as actions from "./actionTypes";
import TimesheetApi from "./TimesheetApi";

export type TimesheetAction =
    ({ type: actions.LOAD_TIMESHEETS_SUCCESS } & Payload<Timesheet[]>)
    | ({ type: actions.LOAD_TIMESHEET_SUCCESS } & Payload<Timesheet>)
    | ({ type: actions.APPROVE_TIMESHEET_SUCCESS } & Payload<number>);

export function loadTimesheetsSuccess(payload: Timesheet[]): TimesheetAction {
    return {
        type: actions.LOAD_TIMESHEETS_SUCCESS,
        payload
    };
}

export function loadTimesheetSuccess(payload: Timesheet): TimesheetAction {
    return {
        type: actions.LOAD_TIMESHEET_SUCCESS,
        payload
    };
}

export function approveTimesheetSuccess(payload: number): TimesheetAction {
    return {
        type: actions.APPROVE_TIMESHEET_SUCCESS,
        payload
    };
}

export function loadTimesheets() {
    return async (dispatch: any, getState: () => AppState) => {
        const timesheetState = getState().router.query.state || "Submitted";
        const timesheets = await loadAndTrack(dispatch, "loadTimesheets", TimesheetApi.getAll(timesheetState));
        dispatch(loadTimesheetsSuccess(timesheets));
    };
}

export function loadTimesheet({ timesheetId }: { timesheetId: number }) {
    return async (dispatch: any) => {
        const timesheet = await loadAndTrack(dispatch, "loadTimesheet", TimesheetApi.get(timesheetId));
        dispatch(loadTimesheetSuccess(timesheet));
    };
}

export function updateTimesheet(timesheet: Timesheet) {
    return async (dispatch: any) => {
        await loadAndTrack(dispatch, "approveTimesheet", TimesheetApi.save(timesheet));
    };
}
