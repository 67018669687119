import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import { ConfigTypeName, HierarchicalConfiguration } from "@momenta/common/hierarchicalConfiguration";

import * as actions from "./actiontypes";
import { hierarchicalConfigurationApi } from "./HierarchicalConfigurationApi";

export type HierarchicalConfigurationAction<T extends HierarchicalConfiguration> =
      ({ type: actions.LOAD_HIERARCHICAL_CONFIGURATION_SUCCESS } & { configType: ConfigTypeName } & Payload<T[]>);

export const loadConfigurationSuccess = <T extends HierarchicalConfiguration>(
    configType: ConfigTypeName,
    payload: T[]
): HierarchicalConfigurationAction<T> => ({
        payload,
        configType,
        type: actions.LOAD_HIERARCHICAL_CONFIGURATION_SUCCESS
    });

export function loadConfiguration<T extends HierarchicalConfiguration>(type: ConfigTypeName) {
    return ({ timesheetId }: { timesheetId: number }) => {
        return async (dispatch: any) => {
            const result = await loadAndTrack(dispatch, "loadConfiguration", hierarchicalConfigurationApi.get<T>(type, timesheetId));
            dispatch(loadConfigurationSuccess<T>(type, result.items));
        };
    };
}
