import { Payload } from "@neworbit/redux-helpers";

import * as actions from "./actiontypes";
import * as models from "./model";

export type CurrentUserAction =
    ({ type: actions.GET_CURRENT_USER_SUCCESS } & Payload<models.CurrentUser>);

export const getCurrentUserSuccess = (payload: models.CurrentUser): CurrentUserAction => ({
    payload,
    type: actions.GET_CURRENT_USER_SUCCESS
});
