import * as React from "react";
import { connect } from "react-redux";
import { Button, Grid } from "semantic-ui-react";
import { LoadingState } from "redux-request-loading";
import {
    AppState,
    getEndDateTimesheet,
    Timesheet,
    TimesheetStateEnum } from "@momenta/common/timesheets";
import { BonusTypeConfiguration, ConfigurationState, ExpenseTypeConfiguration, TimeTypeConfiguration } from "@momenta/common/hierarchicalConfiguration";
import { RejectModel, RejectTimesheetModal } from "@momenta/common/timesheets";
import { toast } from "@momenta/common/toasts";
import { Authorize } from "reauthorize";
import { TimesheetViews } from "@momenta/common/timesheets/TimesheetViews";

import { CurrentUser, UserRoles } from "../auth";

import { timesheetSelector } from "./selectors";
import { loadTimesheet, updateTimesheet } from "./actions";

interface TimesheetDetailProps {
    timesheet: Timesheet;
    expenseTypeConfigurations: ExpenseTypeConfiguration[];
    timeTypeConfigurations: TimeTypeConfiguration[];
    bonusTypeConfigurations: BonusTypeConfiguration[];
}

interface TimesheetDetailDispatchProps {
    onUpdate: (timesheet: Timesheet) => Promise<void>;
}

interface TimesheetDetailState {
    deleteModalOpen: boolean;
    loading: boolean;
}

export class TimesheetDetailUnconnected extends React.Component<TimesheetDetailProps & TimesheetDetailDispatchProps, TimesheetDetailState> {

    public state: TimesheetDetailState = {
        deleteModalOpen: false,
        loading: false
    };

    constructor(props: any) {
        super(props);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    public render() {
        const { timesheet, timeTypeConfigurations, bonusTypeConfigurations, expenseTypeConfigurations } = this.props;
        const { loading } = this.state;
        const startDate = timesheet?.start;
        const endDate = timesheet?.end ?? getEndDateTimesheet(timesheet);
        const dateRange = startDate && `${startDate.format("LL")} - ${endDate?.format("LL")}`;
        const isNotSubmitted = timesheet && timesheet.state !== TimesheetStateEnum.Submitted;

        const approveCondition = (user: CurrentUser) => {
            return user.clientsManaged.every(c => c !== timesheet.invoicePeriod.candidate.role.project.client.id);
        };

        return (
            <>
                {timesheet &&
                    <TimesheetViews
                        dateRange={dateRange}
                        timesheet={timesheet}
                        timeTypeConfigurations={timeTypeConfigurations}
                        bonusTypeConfigurations={bonusTypeConfigurations}
                        expenseTypeConfigurations={expenseTypeConfigurations}
                    />
                }

                <Authorize authorize={UserRoles.timesheetWrite} condition={approveCondition}>
                    <Grid.Column width={8}>
                        <Button
                            content="Reject"
                            color="red"
                            disabled={isNotSubmitted || loading}
                            loading={loading}
                            onClick={this.openModal}
                        />
                        <Button
                            content="Approve"
                            color="green"
                            disabled={isNotSubmitted || loading}
                            loading={loading}
                            onClick={this.onApproved}
                            floated="right"
                        />
                    </Grid.Column>
                </Authorize>

                {this.state.deleteModalOpen &&
                    <RejectTimesheetModal
                        open={this.state.deleteModalOpen}
                        close={this.closeModal}
                        save={this.onRejected}
                    />
                }
            </>
        );
    }

    private openModal() {
        this.open(true);
    }

    private closeModal() {
        this.open(false);
    }

    private onApproved = async () => {
        this.setState({ loading: true });
        const newTimesheet = {
            ...this.props.timesheet,
            state: TimesheetStateEnum.Approved
        };
        await this.props.onUpdate(newTimesheet);
        this.setState({ loading: false });
        toast.success("Timesheet approved");
    }

    private onRejected = async (model: RejectModel) => {
        this.setState({ loading: true });
        const newTimesheet = {
            ...this.props.timesheet,
            state: TimesheetStateEnum.Rejected,
            rejectionReason: model.rejectionReason
        };

        await this.props.onUpdate(newTimesheet);
        this.setState({ loading: false });
        this.open(false);
        toast.success("Timesheet rejected");
    }

    private open(deleteModalOpen: boolean) {
        this.setState({
            deleteModalOpen
        });
    }
}

const mapStateToProps = (state: AppState & ConfigurationState & LoadingState): TimesheetDetailProps => {
    return {
        timesheet: timesheetSelector(state),
        timeTypeConfigurations: state.timeTypeConfiguration,
        expenseTypeConfigurations: state.expenseTypeConfiguration,
        bonusTypeConfigurations: state.bonusTypeConfiguration
    };
};

const mapDispatchToProps = (dispatch: any): TimesheetDetailDispatchProps => ({
    onUpdate: async (timesheet: Timesheet) => {
        await dispatch(updateTimesheet(timesheet));
        await dispatch(loadTimesheet({ timesheetId: timesheet.id }));
    }
});

export const TimesheetDetail = connect(mapStateToProps, mapDispatchToProps)(TimesheetDetailUnconnected);
